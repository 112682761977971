import React from 'react';
import Layout from '../containers/gatsby/layout'
import SEO from '../containers/gatsby/seo'
import DownloadFree from '../containers/sections/DownloadFree/DownloadFree';
import STYLES from '../styles/shared';
import {css} from 'aphrodite';

const NothingHerePage = (props) => (
  <Layout location={props.location}>
    <SEO title='404: Not found' />
    <div className={css(STYLES.documentContent)}>
      <h1>Oops!</h1>
      <p>We're sorry, we couldn't find the page you requested.</p>
    </div>
    <DownloadFree/>
  </Layout>
);

export default NothingHerePage;
